.demo {
    margin: 1rem auto;
    padding: 0rem 2.2rem;
    width: 100%;
}





.demo .one {
    font-size: 1.8rem;
    font-style: italic;
    margin: .5rem auto;
}

.demo .two {
    font-size: 3rem;
    font-weight: 600;
    margin: .5rem auto;
}

.demo .three {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    margin: .5rem auto;
}

.demo iframe{
    padding-top:40px ;
   
    width:100%;
    max-width: 570px;
    height: 100%;
    min-height: 320px;
    
}















