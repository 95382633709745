.header {
    position: fixed;
    height: 110px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    background-color: rgb(256,256,256);
    
}
.header-bg{
    background-color:transparent; 

}

/* .logoHeading{
  color:white;
  
} */

.header-bg .logo2{
  width: 50px;
    height: 50px;
}
.header .navbar {
    display: flex;
    justify-content: start;
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}

.header .nav-menu a {
    color: black;
}
.header-bg .nav-menu a {
    color: white;
}


.header .navbar img {
    width: 250px;
    height: auto;

}

.header .navbar .image2{
  width: 60px;
  height: 60px;
}
.flexx{
  display: flex;
}
.Heading1{
  flex: 1;
  color: white;
  align-items: center;
  margin: auto;
  padding-left: 5px;
  padding-right:2rem;
  font-size: 24px;
  text-transform: uppercase;
}

.header .nav-menu {
    display: flex;
}

.header .nav-item {
    padding: 1rem;
    font-weight: 600;
    font-family: 'League Spartan', sans-serif;
}

.header .NavButton{
    display: flex;
    justify-content: flex-end;
    align-items: center;
   
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
    font-family: 'League Spartan', sans-serif;
  
    
      
}

.header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.hamburger {
    display: none;
}
/* .container {
    display: flex;
  } */
  
  .one,
  .two,
  .three{
    display: inline-block;
  }
  
  .two {
    flex: 1;
  }

  /* .button-17 {
    align-items: center;
    appearance: none;
    background-color: #fff;
    border-radius: 24px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
    box-sizing: border-box;
    color: #3c4043;
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 48px;
    justify-content: center;
    letter-spacing: .25px;
    line-height: normal;
    max-width: 100%;
    overflow: visible;
    padding: 2px 24px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 120px;
    will-change: transform,opacity;
    z-index: 0;
  }
  
  .button-17:hover {
    background: #F6F9FE;
    color: #174ea6;
  }
  
  .button-17:active {
    box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
    outline: none;
  }
  
  .button-17:focus {
    outline: none;
    border: 2px solid #4285f4;
  }
  
  .button-17:not(:disabled) {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }
  
  .button-17:not(:disabled):hover {
    box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
  }
  
  .button-17:not(:disabled):focus {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }
  
  .button-17:not(:disabled):active {
    box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
  }
  
  .button-17:disabled {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  } */



@media screen and (max-width:940px) {
    .header {
        max-width: 100%;
        background-color: rgb(256,256,256);
    }

    .header .navbar {
        max-width: 100%;
    }

    .hamburger {
        display: block;
        display: flex;
    justify-content: end;
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
    }
    .header .NavButton{
        display: none;
       
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: column;
        background-color: white;
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }

    .nav-menu.active {
        left: 0;
    }
    .nav-menu a{
      color:black
    }
    .header .nav-menu a {
      color: black;
  }
    .nav-item {
        margin: 1.5rem 0;
        
    }

    .header .navbar img {
        width: 150px;
    }
}
















