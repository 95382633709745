@import url(https://fonts.googleapis.com/css2?family=League+Spartan:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=League+Spartan:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=League+Spartan:wght@100&family=Playfair+Display:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=League+Spartan:wght@100&family=Merriweather:wght@300&family=Playfair+Display:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bungee&display=swap);

:root {
  --primary-color: #F49711;
  --primary-dark: #787878;
  --secondary-color: #F49711;
  --secondary-dark:#787878;
  --overlay: rgba(0,0,0,.7);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* overflow-x: hidden;  */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.2;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.button {
  padding: 17px 28px;
  font-size: 1.2rem;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #F49711;
  background-color: var(--primary-color);
  border: 1px solid #F49711;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background: #787878;
  background: var(--primary-dark);
  border: 1px solid #787878;
  border: 1px solid var(--primary-dark);
}

.line {
  display: block;
  width: 40px;
  height: 3px;
  margin: 5px auto;
  background-color: #F49711;
  background-color: var(--primary-color);
}

.line2 {
  display: block;
  width: 60px;
  height: 3px;
  margin-bottom: 7px ;
  background-color: #F49711;
  background-color: var(--primary-color);
}

@media screen and (max-width:940px) {
 
html, body{
  overflow-x: hidden;
}
  
}
.header {
    position: fixed;
    height: 110px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    background-color: rgb(256,256,256);
    
}
.header-bg{
    background-color:transparent; 

}

/* .logoHeading{
  color:white;
  
} */

.header-bg .logo2{
  width: 50px;
    height: 50px;
}
.header .navbar {
    display: flex;
    justify-content: start;
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}

.header .nav-menu a {
    color: black;
}
.header-bg .nav-menu a {
    color: white;
}


.header .navbar img {
    width: 250px;
    height: auto;

}

.header .navbar .image2{
  width: 60px;
  height: 60px;
}
.flexx{
  display: flex;
}
.Heading1{
  flex: 1 1;
  color: white;
  align-items: center;
  margin: auto;
  padding-left: 5px;
  padding-right:2rem;
  font-size: 24px;
  text-transform: uppercase;
}

.header .nav-menu {
    display: flex;
}

.header .nav-item {
    padding: 1rem;
    font-weight: 600;
    font-family: 'League Spartan', sans-serif;
}

.header .NavButton{
    display: flex;
    justify-content: flex-end;
    align-items: center;
   
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
    font-family: 'League Spartan', sans-serif;
  
    
      
}

.header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.hamburger {
    display: none;
}
/* .container {
    display: flex;
  } */
  
  .one,
  .two,
  .three{
    display: inline-block;
  }
  
  .two {
    flex: 1 1;
  }

  /* .button-17 {
    align-items: center;
    appearance: none;
    background-color: #fff;
    border-radius: 24px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
    box-sizing: border-box;
    color: #3c4043;
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 48px;
    justify-content: center;
    letter-spacing: .25px;
    line-height: normal;
    max-width: 100%;
    overflow: visible;
    padding: 2px 24px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 120px;
    will-change: transform,opacity;
    z-index: 0;
  }
  
  .button-17:hover {
    background: #F6F9FE;
    color: #174ea6;
  }
  
  .button-17:active {
    box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
    outline: none;
  }
  
  .button-17:focus {
    outline: none;
    border: 2px solid #4285f4;
  }
  
  .button-17:not(:disabled) {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }
  
  .button-17:not(:disabled):hover {
    box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
  }
  
  .button-17:not(:disabled):focus {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }
  
  .button-17:not(:disabled):active {
    box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
  }
  
  .button-17:disabled {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  } */



@media screen and (max-width:940px) {
    .header {
        max-width: 100%;
        background-color: rgb(256,256,256);
    }

    .header .navbar {
        max-width: 100%;
    }

    .hamburger {
        display: block;
        display: flex;
    justify-content: end;
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
    }
    .header .NavButton{
        display: none;
       
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: column;
        background-color: white;
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }

    .nav-menu.active {
        left: 0;
    }
    .nav-menu a{
      color:black
    }
    .header .nav-menu a {
      color: black;
  }
    .nav-item {
        margin: 1.5rem 0;
        
    }

    .header .navbar img {
        width: 150px;
    }
}

















.hero {
    /* background-color: var(--overlay); */
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;
}

.hero:before {
    content: '';
    background: url(/static/media/hero.1fc63d08.jpg) no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.hero .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    margin: auto;
    padding: 1rem;
}

.hero .content p:first-child {
    font-style: italic;
    font-size: .9rem;
}

.hero .content p:nth-child(2) {
    font-size: 1.9rem;
}

.hero .content p:nth-child(3) {
    font-size: 5rem;
    font-weight: 700;
}


.hero .content p:nth-child(4) {
    font-size: 1.5rem;
    font-style: italic;
}

.hero .button {
    margin: 1rem 0;
    width: 270px;
    background-color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    color: #333;
}

.hero .button:hover {
    background-color: var(--secondary-dark);
    border: 1px solid var(--secondary-dark);
}

@media screen and (max-width:940px) {
   
    .hero .content p:nth-child(3) {
        font-size: 2.6rem;
    }

    .hero .content p:nth-child(4) {
        font-size: 1.6rem;
    }
}











.INTRO{
    font-family: 'League Spartan', sans-serif;
font-family: 'Playfair Display', serif;
/* font-weight: 200; */
font-size: 1.5rem;
}

.WHO{
    width: 1240px;
    margin: 3rem auto;
    padding: 1rem 2.2rem;
    overflow-y:hidden
}
.WHO h2{
    font-size: 20px;
    color: var(--primary-dark);
    font-weight: lighter;
  
   
}
.Card:hover{
    border: 1px solid #eee;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgb(0,0,0,.5);
    padding: 1rem;
}

.Card{
    border: 1px solid #eee;
    border-radius: 10px;
    /* box-shadow: 0 3px 10px rgb(0,0,0,.5); */
    padding: 1.2rem;
}

.CardHeading{
    font-size: 30px;
    color: black;
    font-weight: bold;
}

@media screen and (max-width:940px) {
    .INTRO {
        font-size: 1.2rem;
    }

    
}

.ourPartnerss h2 {
    font-size: 2.5rem;
    text-align: center;
}



















.testimonials {
    width: 100%;
 
    margin: 2rem auto;
    padding: 1rem 0rem;
}



.testimonials h2 {
    font-size: 3rem;
    text-align: center;
}


/* the parent */
.slick-list {
    margin: 0 27px;
}

.testimonials .card {
    border: 1px solid #eee;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgb(0,0,0,.2);
    padding: 1rem;
    margin: 1rem auto;
    height: 320px;
    max-width: 300px;
}
.testimonials .card:hover {
    border: 1px solid #eee;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgb(0,0,0,.5);
    padding: 1.2rem;
}
.testimonials img {
    border-radius: 50px;
    margin-top: -2rem;
    height: 70px;
    width: 70px;
}


.testimonials .card p {
    color: #787878;
    font-size: 1.1rem;
    margin: 12px;
}

.testimonials .card span {
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: 700;
}



@media screen and (max-width: 940px) {
   

   

    .testimonials .card {
        max-width: 100%;
        margin: 1rem;
    }
}












 .OurServices {
   
    width: 100%;
    margin: 2rem auto;
    padding: 1rem 2.2rem;
    
} 

 .OurServices h2 {
    font-size: 3rem;
    text-align: center;
}



.BotImage{
height: 400px ;
width: 400px;
}
.demo {
    margin: 1rem auto;
    padding: 0rem 2.2rem;
    width: 100%;
}





.demo .one {
    font-size: 1.8rem;
    font-style: italic;
    margin: .5rem auto;
}

.demo .two {
    font-size: 3rem;
    font-weight: 600;
    margin: .5rem auto;
}

.demo .three {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    margin: .5rem auto;
}

.demo iframe{
    padding-top:40px ;
   
    width:100%;
    max-width: 570px;
    height: 100%;
    min-height: 320px;
    
}
















.SeeWhat {
    width: 100%;
    background-color: var(--primary-color);
    color: #eee;
    padding: 3rem 1rem;
    margin-top: 4rem;
}

.SeeWhat .container {
    max-width: 1240px;
    margin: auto;
    text-align: center;
}

.SeeWhat .container h3 {
  font-family: 'Bungee', cursive;
font-size: 3.5rem;
font-weight: 500;
}

.SeeWhat .container p {
 
  font-family: 'Bungee', cursive;
font-size: 1rem;
font-weight: 300;
}


.bottom {
    text-align: center;
    
}

.line {
    margin-bottom: 1rem;
}
.DemoButton{
    padding-top: 3rem;
}
.button-17 {
    align-items: center;
    -webkit-appearance: none;
            appearance: none;
    background-color: #fff;
    border-radius: 24px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
    box-sizing: border-box;
    color: #3c4043;
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 48px;
    justify-content: center;
    letter-spacing: .25px;
    line-height: normal;
    max-width: 100%;
    overflow: visible;
    padding: 2px 24px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,-webkit-transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms,-webkit-transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 120px;
    will-change: transform,opacity;
    z-index: 0;
  }
  
  .button-17:hover {
    background: #F6F9FE;
    color: #174ea6;
  }
  
  .button-17:active {
    box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
    outline: none;
  }
  
  .button-17:focus {
    outline: none;
    border: 2px solid #4285f4;
  }
  
  .button-17:not(:disabled) {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }
  
  .button-17:not(:disabled):hover {
    box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
  }
  
  .button-17:not(:disabled):focus {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }
  
  .button-17:not(:disabled):active {
    box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
  }
  
  .button-17:disabled {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }
.footer {
    width: 100%;
    background-color: #000;
    color: #eee;
    padding: 3rem 1rem;
}

.footer .container {
    max-width: 1240px;
    margin: auto;
    text-align: center;
}

.footer ul {
    display: flex;
    justify-content: center;
}

.footer li {
    padding: 1rem;
}

.footer li a {
    color: #fff;
}

.footer li a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.bottom {
    text-align: center;
    padding-top: 3rem;
}

.line {
    margin-bottom: 1rem;
}






/* .parallax {
	background: url("https://www.itl.cat/pngfile/big/157-1572191_hd-wallpapers-for-website-background-cool-background-image.jpg") no-repeat fixed 100%;
	text-align: center;
}
.parallax-inner {
	padding-top: 30%;
	padding-bottom: 30%;
}
/*--- End Parallax Style --*/
/* .parallax-inner div {
	text-align: center;
	font-size: 500%;
	color: #FFF;
	text-transform: uppercase;
	letter-spacing: 3px;
}	
.margin {
	margin: 5% 0;
}

     */ 

