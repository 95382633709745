

 .OurServices {
   
    width: 100%;
    margin: 2rem auto;
    padding: 1rem 2.2rem;
    
} 

 .OurServices h2 {
    font-size: 3rem;
    text-align: center;
}


