.testimonials {
    width: 100%;
 
    margin: 2rem auto;
    padding: 1rem 0rem;
}



.testimonials h2 {
    font-size: 3rem;
    text-align: center;
}


/* the parent */
.slick-list {
    margin: 0 27px;
}

.testimonials .card {
    border: 1px solid #eee;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgb(0,0,0,.2);
    padding: 1rem;
    margin: 1rem auto;
    height: 320px;
    max-width: 300px;
}
.testimonials .card:hover {
    border: 1px solid #eee;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgb(0,0,0,.5);
    padding: 1.2rem;
}
.testimonials img {
    border-radius: 50px;
    margin-top: -2rem;
    height: 70px;
    width: 70px;
}


.testimonials .card p {
    color: #787878;
    font-size: 1.1rem;
    margin: 12px;
}

.testimonials .card span {
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: 700;
}



@media screen and (max-width: 940px) {
   

   

    .testimonials .card {
        max-width: 100%;
        margin: 1rem;
    }
}









