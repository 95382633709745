.INTRO{
    font-family: 'League Spartan', sans-serif;
font-family: 'Playfair Display', serif;
/* font-weight: 200; */
font-size: 1.5rem;
}

.WHO{
    width: 1240px;
    margin: 3rem auto;
    padding: 1rem 2.2rem;
    overflow-y:hidden
}
.WHO h2{
    font-size: 20px;
    color: var(--primary-dark);
    font-weight: lighter;
  
   
}
.Card:hover{
    border: 1px solid #eee;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgb(0,0,0,.5);
    padding: 1rem;
}

.Card{
    border: 1px solid #eee;
    border-radius: 10px;
    /* box-shadow: 0 3px 10px rgb(0,0,0,.5); */
    padding: 1.2rem;
}

.CardHeading{
    font-size: 30px;
    color: black;
    font-weight: bold;
}

@media screen and (max-width:940px) {
    .INTRO {
        font-size: 1.2rem;
    }

    
}