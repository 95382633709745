
.ourPartnerss h2 {
    font-size: 2.5rem;
    text-align: center;
}


















