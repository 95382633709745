
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100&family=Playfair+Display:wght@600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100&family=Merriweather:wght@300&family=Playfair+Display:wght@600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');




:root {
  --primary-color: #F49711;
  --primary-dark: #787878;
  --secondary-color: #F49711;
  --secondary-dark:#787878;
  --overlay: rgba(0,0,0,.7);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* overflow-x: hidden;  */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.2;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.button {
  padding: 17px 28px;
  font-size: 1.2rem;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background: var(--primary-dark);
  border: 1px solid var(--primary-dark);
}

.line {
  display: block;
  width: 40px;
  height: 3px;
  margin: 5px auto;
  background-color: var(--primary-color);
}

.line2 {
  display: block;
  width: 60px;
  height: 3px;
  margin-bottom: 7px ;
  background-color: var(--primary-color);
}

@media screen and (max-width:940px) {
 
html, body{
  overflow-x: hidden;
}
  
}